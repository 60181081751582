
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: #f4f6f8;
}

a {
    text-decoration: none;
}

:not(pre) > code {
    font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
    background-color: #eeeeee;
    padding: 2px 4px;
    direction: ltr;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    font-size: 14px;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter'), url(./assets/fonts/inter/Inter-Regular.ttf) format('truetype');
  }

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  src: local('Inter'), url(./assets/fonts/inter/Inter-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 800;
  src: local('Inter'), url(./assets/fonts/inter/Inter-ExtraBold.ttf) format('truetype');
}
@font-face {
    font-family: 'Inter';
    font-weight: 700;
    src: local('Inter'), url(./assets/fonts/inter/Inter-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: local('Inter'), url(./assets/fonts/inter/Inter-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: local('Inter'), url(./assets/fonts/inter/Inter-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: local('Inter'), url(./assets/fonts/inter/Inter-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 200;
  src: local('Inter'), url(./assets/fonts/inter/Inter-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: local('Inter'), url(./assets/fonts/inter/Inter-Thin.ttf) format('truetype');
}

